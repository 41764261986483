body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    height: 100%;
}

.jc-between {
    justify-content: space-between !important;
}

.ai-center {
    align-items: center !important;
}

.jc-center {
    justify-content: center !important;
}

.d-flex {
    display: flex !important;
}

.button-box {
    width: 100%;
}

.icon-box {
    position: relative;
}

.button-box button {
    border-radius: 49px;
    color: #ffffff;
    width: 121px;
    padding: 15px 0;
    background: #393939;
}

.bd4-b {
    line-height: 120%;
    font-weight: 700;
    font-size: 0.875rem;
    letter-spacing: 0.25px;
}

.confirm {
    height: 100%;
}

.confirm-box.act-c555 button {
    background-color: #393939 !important;
    border: 1px solid #393939 !important;
    color: #ffffff !important;
    transition: 0.2s ease-out !important;
}

.outer {
    height: 100%;
}

.inner {
    height: 100vh;
}

.btn-wallet-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.wallet-wrap {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorBox {
    text-align: center;
    color: red;
    margin-top: 10px;
    margin-bottom: 10px;
}

.btn-wallet-box {
    max-width: 600px;
    margin: 0 auto;
    position: relative;
}

@media screen and (min-width: 600px) {
    .btn-wallet-box {
        width: 100%;
        padding: 0 30px;
    }
}

.wallet-btn {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 8px 38px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(230, 230, 230, 0.25);
    border-radius: 8px;
}

@media screen and (max-width: 599px) {
    .wallet-btn {
        width: 80%;
        padding: 8px 30px;
    }
}

.wallet-btn .img-box img {
    width: 36px;
    height: 36px;
}

.icon-box img {
    width: 338px;
    height: 338px;
}

@media screen and (max-width: 700px) {
    .icon-box img {
        width: 160px;
        height: 160px;
    }
}

.btn-wallet-box .title {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.8px;
    color: #1e1e1e;
    margin-bottom: 25px;
    text-align: center;
}

@media screen and (max-width: 430px) {
    .btn-wallet-box .title {
        width: 90%;
    }
}

.btn-wallet-box .title2 {
    color: #1e1e1e;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;
    text-align: center;
    margin-bottom: 60px;
}

@media screen and (max-width: 700px) {
    .btn-wallet-box .title2 {
        font-size: 20px;
    }
}

@media screen and (max-width: 430px) {
    .btn-wallet-box .title2 {
        margin-bottom: 20px;
    }
}

.btn-wallet-box .content {
    size: 14px;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.8px;
    color: #9b9b9b;
    width: 600px;
}

@media screen and (max-width: 700px) {
    .btn-wallet-box .content {
        width: 330px;
    }
}

@media screen and (max-width: 430px) {
    .btn-wallet-box .content {
        width: 90%;
    }
}

.btn-wallet-box .loginBtn {
    width: 100%;
    display: block;
    background: black;
    color: white;
    border-radius: 40px;
    padding: 20px 0;
    outline: none;
    border: none;
    font-size: 16px;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.25px;
}

@media screen and (max-width: 599px) {
    .btn-wallet-box .loginBtn {
        width: 90%;
    }
}

.confirm .close-btn {
    position: fixed;
    cursor: pointer;
    top: 60px;
    right: 15px;
    width: 86px;
    height: 46px;
    size: 16px;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.25px;
    color: #1e1e1e;
    background: #ffffff;
    border: 1px solid #c7c7c7;
    border-radius: 40px;
}
